import React, { Component } from 'react';
import '../styles/App.css';
import { motion } from "framer-motion";
import { Row, Col } from 'react-bootstrap';
import { animationVariables } from '../utils/Constants';

export default class Section05 extends Component {
    render() {
        return (
            <div className="section05">
                <div className="section05Container">
                    <Row>
                        <Col md={4}>
                            {this.props.isVisible ?
                                <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                    <h1 className="section05Title">Creative <br />Director<span className="finalDot">.</span></h1>
                                    <p className="section05Subtitle">Lindsey Aliksanyan is an award-winning creative of Armenian and British descent with over a decade of experience as an editor, director and producer in the broadcast & film industries.</p>
                                </motion.div>
                                : null}
                        </Col>

                        <Col md={{ size: 8 }}>
                            {this.props.isVisible ?
                                <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                    <p className="section05Name d-none d-sm-none d-md-block">Lindsey<br /> Aliksanyan</p>

                                    <div className="awards">
                                        <img src="/images/award-1.png" />
                                        <img src="/images/award-3.png" />
                                        <img src="/images/award-4.png" />
                                    </div>
                                </motion.div>
                                : null}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}