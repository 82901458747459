import React, { Component } from 'react';
import '../styles/App.css';
import Lottie from 'react-lottie-segments';
import ResultsFrom from '../resources/lotties/ResultsFrom.json';
import graph from '../resources/lotties/graph.json';
import 'react-animated-slider/build/horizontal.css';
import Carousel from 'react-bootstrap/Carousel';

export default class Section02 extends Component {
    state = {
        sliderIndex: 0
    };

    render() {
        const defaultOptionsResultsFrom = {
            loop: false,
            autoPlay: false,
            animationData: ResultsFrom,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
            }
        };

        const defaultOptionsGraph = {
            loop: false,
            autoPlay: false,
            animationData: graph,
            rendererSettings: {
                preserveAspectRatio: 'none'
            }
        };

        const slides = [
            { description: 'Marketing like everyone else without Onyx.', image: "Frame1.png" },
            { description: 'Client adoption of Onyx marketing strategies.', image: "Frame2.png" },
            { description: '"I have never seen Casino Marketing like this. Instant results in revenue growth. Like lightning in a bottle." Senior Executive, Ceasars Entertainment Corporation', image: "Frame3.png" },
            { description: '"Just as I thought revenue had peaked for our casino, it took off again. It was unbelievable!" Chairman / Chief of Tribal Casino', image: "Frame4.png" }
        ];

        return (
            <div className="section02" style={{ overflow: 'hidden' }}>
                <div className="section02Container d-none d-sm-block">
                    <div style={{ margin: '87px 0 0 65px', position: 'fixed', width: '500px' }}>
                        <Lottie isClickToPauseDisabled options={defaultOptionsResultsFrom} isStopped={!this.props.isVisible} />
                    </div>

                    <div style={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
                        <Lottie isClickToPauseDisabled style={{ margin: '0' }} options={defaultOptionsGraph} width={`100%`} height={'80vh'} isStopped={!this.props.isVisible} />
                    </div>
                </div>

                {this.props.isVisible ?
                    <div className="section02Container section02ContainerSmall d-flex d-sm-none">
                        <div id="sliderMobile">
                            <Carousel activeIndex={this.state.sliderIndex} onSelect={(selectedIndex, e) => this.setState({ sliderIndex: selectedIndex })}
                                controls={false} indicators={false} interval={3000} keyboard={false} pause={false} touch={false} >
                                {slides.map((obj, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            <h3 className="sliderTitle">{obj.description}</h3>
                                            <img src={`/images/${obj.image}`} style={{ width: '100%', marginTop: '10vh' }} />
                                        </Carousel.Item>
                                    )
                                })
                                }
                            </Carousel>
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }
}