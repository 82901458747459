import React, { Component } from 'react';
import '../styles/App.css';
import { motion } from "framer-motion";
import { Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import { animationVariables } from '../utils/Constants';

export default class Section04 extends Component {
    goFullscreen = () => {
        var videoContainer = document.getElementById("videoSection04");

        if (videoContainer.requestFullscreen) videoContainer.requestFullscreen();
        else if (videoContainer.mozRequestFullScreen) videoContainer.mozRequestFullScreen();
        else if (videoContainer.webkitRequestFullScreen) videoContainer.webkitRequestFullScreen();
        else if (videoContainer.msRequestFullscreen) videoContainer.msRequestFullscreen();
    };

    render() {
        return (
            <div className="section04">
                <div className="section04Container">
                    <Row>
                        <Col md={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            {this.props.isVisible ?
                                <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                    <div>
                                        <h1 className="section04Title">Creative that builds casino revenue<span className="finalDot">.</span></h1>
                                        <p className="section04Subtitle">AWARDS WINNING, ANIMATION AND COMMERCIAL PRODUCTION, ALL AT OUR CLIENTS FINGERTIPS.</p>

                                        <button style={{ marginTop: '0' }} onClick={() => this.goFullscreen()} className="btnAction d-none d-sm-none d-md-block">VIEW FULL SCREEN</button>
                                    </div>
                                </motion.div>
                                : null}

                            {this.props.isVisible ?
                                <Row className="d-none d-sm-flex">
                                    <Col sm={12}>
                                        <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                            <p className="counterTitle"><CountUp start={this.props.isVisible ? 0 : 54.45} end={54.45} duration={2} separator=" " decimals={2} decimal="." startOnMount={true} redraw={true} /> MIL+</p>
                                            <p className="counterSubtitle">AD SPEND</p>
                                        </motion.div>
                                    </Col>

                                    <Col sm={6}>
                                        <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                            <p className="counterTitle"><CountUp start={this.props.isVisible ? 0 : 294} end={294} duration={2} separator=" " startOnMount={true} redraw={true} />+</p>
                                            <p className="counterSubtitle">CREATIVE PROJECTS</p>
                                        </motion.div>
                                    </Col>

                                    <Col sm={6}>
                                        <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                            <p className="counterTitle"><CountUp start={this.props.isVisible ? 0 : 152} end={152} duration={2} separator=" " startOnMount={true} redraw={true} />+</p>
                                            <p className="counterSubtitle">CAMPAIGNS</p>
                                        </motion.div>
                                    </Col>

                                    <img src="/images/line.png" style={{ position: 'absolute', left: '0px', bottom: '-12%', zIndex: '1' }} />
                                </Row>
                                : null}
                        </Col>

                        <Col md={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0' }}>
                            {this.props.isVisible ?
                                <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}
                                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div className="section04Video">
                                        <video id="videoSection04" autoPlay muted loop controls style={{ width: '100%', borderRadius: '15px' }}>
                                            <source src="/videos/section04.mp4" type="video/mp4" />
                                        </video>
                                    </div>

                                    <img src="/images/corner.png" style={{ alignSelf: 'flex-end', position: 'absolute', right: '4%', zIndex: '1' }} />
                                </motion.div>
                                : null}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}