import React, { Component } from 'react';
import '../styles/App.css';
import Lottie from 'react-lottie-segments';
import OddsAre from '../resources/lotties/OddsAre.json';
import { Row } from 'react-bootstrap';

export default class Section01 extends Component {
    state = {
        isLoading: true
    };

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 3600);
    };

    componentDidUpdate = () => {
        var mobilevideo = document.getElementsByTagName("video");

        for (var i = 0; i < mobilevideo.length; i++) {
            mobilevideo[i].setAttribute("playsinline", "");
        }
    };

    render() {
        const defaultOptionsLottie = {
            loop: false,
            animationData: OddsAre,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <React.Fragment>
                {
                    this.state.isLoading ?
                        <div></div>
                        : <div className="section01">
                            <video autoPlay muted loop className="videoBg">
                                <source src="/videos/bg-section01.mp4" type="video/mp4" />
                            </video>

                            <div className="videoBgContent section01Content">
                                <Row>
                                    <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                        <div className="lottieSection01Inner">
                                            <Lottie isClickToPauseDisabled options={defaultOptionsLottie} width={`75%`} className="lottieSection01" />
                                        </div>
                                    </div>
                                </Row>

                                <div style={{ bottom: '0', position: 'absolute', marginBottom: '90px', width: '100vw' }}>
                                    <center>
                                        <button className='seeMoreBtn' onClick={this.props.seeMoreButton}>SEE MORE</button>
                                    </center>
                                </div>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    }
}