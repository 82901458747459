import React, { Component } from 'react';
import '../styles/App.css';
import { motion } from "framer-motion";
import { Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie-segments';
import OddsAre from '../resources/lotties/OddsAre.json';
import ContactForm from './ContactForm';
import { animationVariables } from '../utils/Constants';

export default class Section07 extends Component {
    state = {
        showContact: false,
    };

    render() {
        const defaultOptionsLottie = {
            loop: false,
            animationData: OddsAre,
            rendererSettings: {
                preserveAspectRatio: 'none'
            }
        };

        return (
            <div className="section07">
                <div className="section07Container">
                    <Row>
                        <Col md={6} className="d-none d-sm-none d-md-block">
                            <Row>
                                <div className="col-sm-12">
                                    <div style={{ width: `100%` }}>
                                        {this.props.isVisible ?
                                            <motion.div style={{ display: 'fixed', position: 'absolute', width: '100%', marginTop: '-10%' }}
                                                initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                                <video autoPlay muted loop className="videoContact">
                                                    <source src="/videos/contact.mp4" type="video/mp4" />
                                                </video>
                                            </motion.div>
                                            : null}

                                        <Lottie isClickToPauseDisabled options={defaultOptionsLottie} width={`85%`} style={{ marginTop: `12vh`, paddingTop: `5vh`, marginLeft: '20%' }} isStopped={!this.props.isVisible} />
                                    </div>
                                </div>
                            </Row>
                        </Col>

                        <Col md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {this.props.isVisible ?
                                <motion.div style={{ textAlign: 'center' }}
                                    initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                    <p className="contactInfo">
                                        +1 (539) 867-2998
                                        </p>

                                    <p className="contactInfo">
                                        info@onyxtheagency.com
                                        </p>

                                    <p className="contactInfo  d-none d-sm-block">
                                        320 S Boston Ave, Suite 2300<br />
                                        Tulsa, OK<br />
                                        74103 - USA
                                        </p>

                                    <button className='btnAction' style={{ fontSize: '1.6rem' }} onClick={() => this.setState({ showContact: true })}>CONTACT US</button>
                                </motion.div>
                                : null}
                        </Col>
                    </Row>

                    <ContactForm showModal={this.state.showContact} closeModal={() => this.setState({ showContact: false })} />
                </div>
            </div>
        );
    }
}