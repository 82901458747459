import React, { Component } from 'react';
import '../styles/App.css';
import { motion } from "framer-motion";
import { Row, Col } from 'react-bootstrap';
import { animationVariables } from '../utils/Constants';
import VideoLightBox from './VideoLightBox';
import oSvg from '../resources/svgs/o.svg';

export default class Section06 extends Component {
    state = {
        isOpenModal: false
    };

    componentDidUpdate = () => {
        var mobilevideo = document.getElementsByTagName("video");

        for (var i = 0; i < mobilevideo.length; i++) {
            mobilevideo[i].setAttribute("playsinline", "");
        }
    };

    render() {
        return (
            <div className="section06">
                <VideoLightBox isOpenModal={this.state.isOpenModal} videoId={`384281541`} onClose={() => this.setState({ isOpenModal: false })} />

                <video autoPlay muted loop className="videoBg">
                    <source src="/videos/bg-section06.mp4" type="video/mp4" />
                </video>

                <div className="videoBgContent" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="section06Container">
                        <Row style={{ height: '100%' }}>
                            <Col xl={{ size: 6 }} className="d-none d-sm-none d-md-none d-lg-none d-xl-flex">
                                {this.props.isVisible ?
                                    <motion.div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
                                        initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                        <img style={{ width: '56%' }} src={oSvg} />
                                    </motion.div>
                                    : null}
                            </Col>

                            <Col xl={{ size: 6 }} lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                {this.props.isVisible ?
                                    <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                        <h1 className="section06Title">Live<br /> Entertainment<span className="finalDot">.</span></h1>
                                        <p className="section06Subtitle">We are the only agency in the United States that specializes in nationally televised boxing.</p>

                                        <ul className="section06List d-none d-sm-block">
                                            <li>Creating local and national press, our exclusive relationships with the <b>largest sporting networks</b> in the world increases your exposure and earned media.</li>
                                            <li>Boxing attracts our clients lucrative players and more importantly, <b>players from other casinos.</b></li>
                                            <li>These iconic events have resulted in some of the <b>most lucratives nights</b> on our clients casino floors.</li>
                                        </ul>

                                        <div className="networks">
                                            <img src="/images/section06-logo-3.png" />
                                            <img src="/images/section06-logo-1.png" />
                                            <img src="/images/section06-logo-2.png" />
                                            <img src="/images/section06-logo-4.png" style={{ marginLeft: '20px' }} />
                                        </div>

                                        <button onClick={() => this.setState({ isOpenModal: true })} className="btnAction">WATCH VIDEO</button>
                                    </motion.div>
                                    : null}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}