import React, { Component } from 'react';
import '../styles/App.css';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';

export default class ContactForm extends Component {
    initialState = {
        submitted: false,
        contactForm: {
            name: '',
            email: '',
            phone: '',
            address: '',
            message: '',
        }
    };

    state = this.initialState;

    sendContact = () => {
        if (this.state.contactForm.name.trim() === "" || this.state.contactForm.email.trim() === "" || this.state.contactForm.message.trim() === "") {
            alert("The name, email and message fields are required!");
            return;
        }

        this.setState({ submitted: true }, () => {
            axios.post(`https://1srp7zg2db.execute-api.us-east-2.amazonaws.com/prod/contact`, {
                name: this.state.contactForm.name,
                email: this.state.contactForm.email,
                message: this.state.contactForm.message,
                phone: this.state.contactForm.phone,
                address: this.state.contactForm.address
            }).catch(err => {
            }).then(res => {
                alert("Thanks, " + this.state.contactForm.name + "! Your message was successfully sent. We'll get back to you soon.");

                this.setState(this.initialState, () => {
                    this.props.closeModal();
                });
            });
        });
    };

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton />

                <Modal.Body style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Row>
                        <Col sm={12}>
                            <input type="text" className="inputContact" placeholder={`Name`} value={this.state.contactForm.name} onChange={(e) => this.setState({ contactForm: { ...this.state.contactForm, name: e.target.value } })} />
                        </Col>

                        <Col sm={12}>
                            <input type="email" className="inputContact" placeholder={`Email`} value={this.state.contactForm.email} onChange={(e) => this.setState({ contactForm: { ...this.state.contactForm, email: e.target.value } })} />
                        </Col>

                        <Col sm={12}>
                            <input type="text" className="inputContact" placeholder={`Phone`} value={this.state.contactForm.phone} onChange={(e) => this.setState({ contactForm: { ...this.state.contactForm, phone: e.target.value } })} />
                        </Col>

                        <Col sm={12}>
                            <input type="text" className="inputContact" placeholder={`Address`} value={this.state.contactForm.address} onChange={(e) => this.setState({ contactForm: { ...this.state.contactForm, address: e.target.value } })} />
                        </Col>

                        <Col sm={12}>
                            <textarea rows="3" style={{ resize: 'none' }} className="inputContact" placeholder={`Message`} value={this.state.contactForm.message} onChange={(e) => this.setState({ contactForm: { ...this.state.contactForm, message: e.target.value } })} />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer style={{ paddingTop: 0 }}>
                    {this.state.submitted ?
                        <div style={{ padding: '10px 20px 10px 20px', textAlign: 'center' }}>
                            <Spinner size="sm" animation="grow" style={{ color: '#6a1b9a' }} />
                        </div>
                        : <button className="sendButton" onClick={this.sendContact}>SEND</button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}