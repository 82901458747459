import React, { Component } from 'react';
import '../styles/App.css'
import '../styles/DeviceRotation.css'
import Fetching from '../components/Fetching';
import { Modal, Row, Col } from 'react-bootstrap';
import Section01 from '../components/Section01';
import Section02 from '../components/Section02';
import Section03 from '../components/Section03';
import Section04 from '../components/Section04';
import Section05 from '../components/Section05';
import Section06 from '../components/Section06';
import Section07 from '../components/Section07';
import { motion } from "framer-motion";
import buttonmenu from '../resources/svgs/buttonmenu.svg';
import ReactFullpage from "@fullpage/react-fullpage";
import ReactGA from 'react-ga';

export default class App extends Component {

  state = {
    data: null,
    loading: true,
    showMenu: false,
    visibleSection: 1
  };

  componentDidMount = () => {
    ReactGA.initialize('UA-181727894-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    setTimeout(() => {
      this.setState({
        loading: false
      });
      //}, 100);
    }, 4700);
  };

  scrollToItem = (pageNumber, callback) => {
    this.setState({ showMenu: false }, () => {
      callback.moveTo(pageNumber + 1, 0);
    });
  };

  onLeave(origin, destination, direction) {
    this.setState({ visibleSection: destination.index + 1 });
  };

  afterLoad(origin, destination, direction) {
    var mobilevideo = document.getElementsByTagName("video");

    for (var i = 0; i < mobilevideo.length; i++) {
      mobilevideo[i].play();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="rotate">
          <div className="phone" />
          <div className="message">
            Please rotate your device!
          </div>
        </div>

        <motion.div style={{ backgroundColor: '#010001', position: 'absolute', zIndex: '99999999', height: '100vh', width: '100vw' }} initial={{ opacity: 1, x: 0, y: 0 }} animate={{
          opacity: 0, x: 0, y: 0, transitionEnd: {
            display: "none",
          },
        }} transition={{ type: "spring", delay: 4.3, stiffness: 200, damping: 100 }}>
          <Fetching />
        </motion.div>

        <div className="header">
          <img src="/images/logo.png" style={{ alignSelf: 'center', width: '100px' }} />

          <p className="d-none d-sm-block onyxTitle">ONYX THE AGENCY</p>

          <div className="btnMenu">
            <button className="btnMenu2" onClick={() => this.setState({ showMenu: true })}>
              <img style={{ marginTop: '-25px', marginLeft: '-75px' }} src={buttonmenu} />
            </button>
          </div>
        </div>

        <ReactFullpage
          scrollOverflow={true}
          touchSensitivity={1}
          onLeave={this.onLeave.bind(this)}
          afterLoad={this.afterLoad.bind(this)}
          render={({ state, fullpageApi }) => {
            return (
              <div style={{ backgroundColor: '#1f2129' }}>
                <React.Fragment>
                  <Modal dialogClassName="custom-modal" bsClass="menu-modal" show={this.state.showMenu} onHide={() => this.setState({ showMenu: false })}>
                    <Modal.Header closeButton />

                    <Modal.Body>
                      <Row style={{ width: '100vw' }}>
                        <Col sm={6}>
                          <div className="menuItems">
                            <button onClick={() => this.scrollToItem(0, fullpageApi)}>Home</button>
                            <button onClick={() => this.scrollToItem(1, fullpageApi)}>Results</button>
                            <a href="https://www.whyonyx.com/services#1" target="_blank">Services</a>
                            <button onClick={() => this.scrollToItem(2, fullpageApi)}>The Way We Think</button>
                            <button onClick={() => this.scrollToItem(3, fullpageApi)}>Creative Reel</button>
                            <button onClick={() => this.scrollToItem(4, fullpageApi)}>Creative Director</button>
                            <button onClick={() => this.scrollToItem(5, fullpageApi)}>Live Entertainment</button>
                            <button onClick={() => this.scrollToItem(6, fullpageApi)}>Contact Us</button>
                          </div>
                        </Col>

                        <Col sm={6} className="d-none d-sm-none d-md-block">
                          <Row className="menuItemRow">
                            <Col sm={6}>
                              <p className="menuItemTitle">Find Us</p>
                              <p className="menuItemSubtitle">
                                320 S Boston Ave, Suite 2300<br />
                                Tulsa, OK<br />
                                74103 - USA
                                 </p>
                            </Col>

                            <Col sm={6}>
                              <p className="menuItemTitle">Contact</p>
                              <p className="menuItemSubtitle">
                                +1 (539) 867-2998<br />
                                info@onyxtheagency.com<br />
                                Instagram
                                 </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>

                  <div id="fullpage-wrapper">
                    <div className="section">
                      <Section01 seeMoreButton={() => fullpageApi.moveTo(2, 0)} />
                    </div>

                    <div className="section">
                      <Section02 isVisible={this.state.visibleSection == 2 ? true : false} />
                    </div>

                    <div className="section">
                      <Section03 isVisible={this.state.visibleSection == 3 ? true : false} />
                    </div>

                    <div className="section section04Background">
                      <Section04 isVisible={this.state.visibleSection == 4 ? true : false} />
                    </div>

                    <div className="section section05Background">
                      <Section05 isVisible={this.state.visibleSection == 5 ? true : false} />
                    </div>

                    <div className="section">
                      <Section06 isVisible={this.state.visibleSection == 6 ? true : false} />
                    </div>

                    <div className="section">
                      <Section07 isVisible={this.state.visibleSection == 7 ? true : false} />
                    </div>
                  </div>
                </React.Fragment>
              </div>
            );
          }}
        />
      </React.Fragment>
    );
  }
}
