import React, { Component } from 'react';
import '../styles/App.css';
import Lottie from 'react-lottie';
import animationData from '../resources/lotties/logoroulette.json';
import logom from '../resources/lotties/logom.json';

export default class Fetching extends Component {

    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const defaultOptionsMobile = {
            loop: false,
            autoplay: true,
            animationData: logom,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div className='appLoading'>
                <Lottie isClickToPauseDisabled style={{ marginTop: '-90px' }} options={defaultOptionsMobile} height={320} width={350} />
            </div>
        );
    }
};