export const animationVariables = {
    initial: {
        opacity: 0, x: 0, y: 100
    },
    animate: {
        opacity: 1, x: 0, y: 0
    },
    transition: {
        type: "spring", delay: 0.25, stiffness: 7, damping: 80
    }
};