import React, { Component } from 'react';
import '../styles/App.css';
import ModalVideo from 'react-modal-video';

export default class VideoLightBox extends Component {

    render() {
        return (
            <ModalVideo channel='vimeo' isOpen={this.props.isOpenModal} videoId={this.props.videoId} onClose={this.props.onClose} />
        );
    }
}