import React, { Component } from 'react';
import '../styles/App.css';
import { motion } from "framer-motion";
import { animationVariables } from '../utils/Constants';
import VideoLightBox from './VideoLightBox';

export default class Section03 extends Component {
    state = {
        isOpenModal: false
    };

    componentDidUpdate = () => {
        var mobilevideo = document.getElementsByTagName("video");

        for (var i = 0; i < mobilevideo.length; i++) {
            mobilevideo[i].setAttribute("playsinline", "");
        }
    };

    render() {
        return (
            <div className="section03">
                <VideoLightBox isOpenModal={this.state.isOpenModal} videoId={`425269417`} onClose={() => this.setState({ isOpenModal: false })} />

                <video autoPlay muted loop className="videoBg">
                    <source src="/videos/bg-section03.mp4" type="video/mp4" />
                </video>

                <div className="videoBgContent section03Content" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="section03Container">
                        {this.props.isVisible ?
                            <motion.div initial={animationVariables.initial} animate={animationVariables.animate} transition={animationVariables.transition}>
                                <div style={{ marginTop: '18vh', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', paddingRight: '6.5vw', flexDirection: 'column' }}>
                                    <img src="images/section03-text.png" style={{ alignSelf: 'right', maxWidth: '90%' }} />
                                    <p className="theWay">THE WAY WE THINK<span className="finalDot">.</span></p>

                                    <button onClick={() => this.setState({ isOpenModal: true })} className="btnAction">WATCH VIDEO</button>
                                </div>
                            </motion.div>
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}